import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

export const mailjetSendersQuery = ['mailjet', 'senders'];

export type MailjetSendersResponse = {
  Email: string;
  ID: number;
  IsDefaultSender: boolean;
  Name: string;
  Status: string;
};

export function useMailjetSendersQuery() {
  return useQuery({
    queryKey: mailjetSendersQuery,
    queryFn: () => fetchData<MailjetSendersResponse>('api/mailjet/get-senders'),
    staleTime: Infinity,
  });
}
