import Cookies from 'universal-cookie';
import { makeSessionCookie } from 'src/app/components/auth/make-session-cookie';
import AppDispatcher from '../utils/AppDispatcher';
import { actions as AppEvents } from '../constants/AppConstants';
import AppApi from '../api/AppApi';
import UserApi from '../api/UserApi';
import CompanyApi from '../api/CompanyApi';
import UserStore from '../stores/UserStore';
import AjaxPromises from '../utils/AjaxPromises';
import { actions as UserActionTypes } from '../constants/UserConstants';
import { actions as AuthActionTypes } from '../constants/AuthConstants';
import NotificationActions from './NotificationActions';

import RSVP from 'rsvp';

export default {
  getBuildNumber: () => {
    AjaxPromises.GET('api/admin/get-build-number').then((res) => {
      AppDispatcher.dispatch({
        actionType: AppEvents.BUILD_NUMBER,
        data: {
          number: res.BuildNumber,
          apiVersionNumber: res.VersionNumber,
          forceRefresh: res.ForceRefresh,
        },
      });
    });
  },
  getRegion: () => {
    AjaxPromises.GET('api/admin/get-region').then((res) => {
      AppDispatcher.dispatch({
        actionType: AppEvents.REGION,
        data: res.Data,
      });
    });
  },
  reloadFeatures: () => {
    UserApi.getLocationSettings(UserStore.getApiData()).then((res) => {
      AppDispatcher.dispatch({
        actionType: UserActionTypes.SET_FEATURES,
        data: {
          features: res.Data.Features,
          integrations: res.Data.Integrations,
          lspSettings: res.Data.Settings,
        },
      });
    });
  },

  getSSOLoginEnabled: () => {
    AjaxPromises.GET('api/saml/SSOLoginEnabled').then((res) => {
      AppDispatcher.dispatch({
        actionType: AuthActionTypes.SSO_LOGIN_ENABLED,
        data: res.isSSOLoginEnabled,
      });
    });
  },

  ssologin: (formData, migrationConfigCb) => {
    AjaxPromises.POST('api/SAML/SSOLoginRedirect', formData).then((res) => {
      if (!res.Result) {
        if (res.Data && res.Data.shouldShowBlockingUI) {
          migrationConfigCb(res.Data)
          return;
        }
        AppDispatcher.dispatch({
          actionType: AuthActionTypes.SSO_LOGIN,
          data: false,
        });
      } else if (res.Result) {
        window.location.href = res.Data;
      }
    });
  },

  getCurrentServer: () => {
    AjaxPromises.GET('api/admin/current-server').then((res) => {
      if (res.Result && res.Data) {
        window.location.href = res.Data;
      }
    });
  },

  boot: (user, isTokenLogin) => {
    const formData = {
      SessionId: user.SessionId,
    };

    AppApi.employeeLoginDetails(formData).then((res) => {
      if (!res.Result || res.Result === 'false') {
        NotificationActions.error(`Login Failed ${res.Message}`);
        UserStore.logout();
        return;
      }

      if (res.Data.Url) {
        const cookies = new Cookies();
        cookies.remove('LeafLogixSessionInfo', { path: '/' });
        NotificationActions.warn('Your password has expired and must be changed');
        setTimeout(() => (window.location = res.Data.Url), 2000);
        return;
      }

      if (res.Data.LspId == 0 || res.Data.LocId == 0) {
        UserStore.logout();
        NotificationActions.error('Current Lsp/Location has been disabled, please contact your administrator');
        return;
      }

      for (const x in res.Data) {
        if (!res.Data[x]) {
          continue;
        }

        if (res.Data[x].toString().toLowerCase() === 'yes') {
          res.Data[x] = true;
        }

        if (res.Data[x].toString().toLowerCase() === 'no') {
          res.Data[x] = false;
        }
      }

      user.LocId = res.Data.LocId;
      user.LspId = res.Data.LspId;
      user.UserName = res.Data.UserName;
      user.Id = user.Id ?? res.Data.UserId;
      user.FullName = user.FullName ?? res.Data.FullName;

      user.Administrator = res.Data.Administrator;
      user.Cultivation = res.Data.Cultivation;
      user.Dispensary = res.Data.Dispensary;
      user.ExportReports = res.Data.ExportReports;
      user.Inspector = res.Data.Inspector;
      user.InventoryReadOnly = res.Data.InventoryReadOnly || false;
      user.Inventory = res.Data.Inventory;
      user.ManagerFlag = res.Data.ManagerFlag;
      user.OfficeAccess = res.Data.OfficeAccess;

      formData.LspId = user.LspId;
      formData.LocId = user.LocId;
      formData.UserId = user.Id;

      if (isTokenLogin) {
        // set cookie using makeSessionCookie()
        const sessionCookie = makeSessionCookie(user, res.Data);
        const cookies = new Cookies();
        cookies.set('LeafLogixSessionInfo', sessionCookie, { path: '/' });
      }

      AjaxPromises.GET('api/admin/get-build-number').then((res) => {
        AppDispatcher.dispatch({
          actionType: AppEvents.BUILD_NUMBER,
          data: {
            number: res.BuildNumber,
            apiVersionNumber: res.VersionNumber,
            forceRefresh: res.ForceRefresh,
          },
        });
      });

      RSVP.all([
        UserApi.getCompanies(formData),
        CompanyApi.getLogo(formData),
        CompanyApi.getLocations(formData),
        UserApi.getUserSettings(user.Id),
        UserApi.getLocationSettings(formData),
        UserApi.getValidatedForms(formData),
        UserApi.loadCustomerTypes(formData),
      ]).then((res) => {
        try {
          let selectedCompany = '';
          for (var i = 0; i < res[0].Data.length; i++) {
            if (res[0].Data[i].Id === user.LspId) {
              selectedCompany = res[0].Data[i];
            }
          }

          if (res[1].Data && res[1].Data[0]) {
            selectedCompany.Logo = res[1].Data[0].Logo;
          }

          let selectedLocation = '';
          for (var i = 0; i < res[2].Data.length; i++) {
            if (res[2].Data[i].LocId === user.LocId) {
              selectedLocation = res[2].Data[i];
            }
          }

          let locProfileConverted = { LocId: formData.LocId };
          res[4].Data.Features.map((feature) => {
            locProfileConverted[feature.FeatureName] = feature.IsEnabled;
          });
          locProfileConverted = Object.assign(locProfileConverted, res[4].Data.Integrations, res[4].Data.Settings);

          const settings = {};
          const tmp = res[3].Data.filter((x) => x.SettingType !== 'table');
          for (var i = 0; i < tmp.length; i++) {
            settings[tmp[i].Setting] = tmp[i].Value;
          }

          let tableSettings = res[3].Data.filter((x) => x.SettingType === 'table');
          tableSettings =
            tableSettings !== undefined
              ? tableSettings.map((x) => ({ table: x.Setting, layout: JSON.parse(x.Value) }))
              : [];

          AppDispatcher.dispatch({
            actionType: AppEvents.APP_BOOT,
            data: {
              user,
              companies: res[0].Data,
              locations: res[2].Data,
              selectedCompany,
              selectedLocation,
              locProfile: locProfileConverted,
              tableSettings,
              settings,
              features: res[4].Data.Features,
              integrations: res[4].Data.Integrations,
              lspSettings: res[4].Data.Settings,
              validatedForms: res[5].Data,
              customerTypes: res[6].Data,
            },
          });
        } catch (err) {
          console.error(err);
        }
      });
    });
  },
};
