import { useMutation } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import type { AxiosError } from 'axios';

export type DeleteMailjetSender = {
  SenderId: number;
};

export function useDeleteMailjetSenderMutation({ onSuccess, onError }) {
  return useMutation<DeleteMailjetSender, AxiosError<{ Data: string; Message: string }>, DeleteMailjetSender>({
    mutationFn: (settings) =>
      postData({
        payload: settings,
        endpoint: 'api/mailjet/delete-sender',
      }),
    onSuccess,
    onError,
  });
}
