import { format } from 'date-fns';
import { json2csv } from 'json-2-csv';
import _ from 'lodash';

import HttpClient from 'src/app_deprecated/utils/HttpClient';
import Utils from 'src/app_deprecated/utils/Utils';

import { getNavStringFromCategoryAndCname } from './helpers';

import type { RunReportSettings } from './report-category-page/individual-report-view';
import type { StaticReport } from './use-reports-list';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { UserState } from 'src/app/state/user';

const CATEGORIES = 'categories';
const CATEGORY = 'category';

export function removeProductCategoryFromCanonicalName(name: string): string {
  const canonicalNameWords = _.words(name);
  const productIndex = canonicalNameWords.findIndex((canonicalNameWord) => canonicalNameWord === 'product');
  const nextWordIndex = productIndex + 1;
  const nextWord = canonicalNameWords[nextWordIndex];

  if (productIndex >= 0 && (nextWord === CATEGORY || nextWord === CATEGORIES)) {
    canonicalNameWords.splice(productIndex, 1);
    return canonicalNameWords.join('-');
  }

  return name;
}

export function getSMBReportOptions({
  listOfReports,
  isDistribution,
  isCultivator,
  isManufacturer,
  inventoryNextGen,
}): DropdownMenuOption[] {
  const filteredRetailListOfReports = listOfReports?.filter(
    ({ ReportCategory, ReportName }) => !ReportName.includes('Cultivation') && !ReportCategory.includes('Cultivation')
  );
  const dropdownSmbRetailOptions = _.map(filteredRetailListOfReports, (report) => ({
    label: report.ReportName,
    id: getNavStringFromCategoryAndCname({
      category: report.ReportCategory,
      canonicalName: report.canonicalName,
      inventoryPerformanceFlag: inventoryNextGen,
    }),
  }));
  let smbOptions = _.uniqBy(dropdownSmbRetailOptions, 'id');

  if (isDistribution) {
    const filteredDistributorListOfReports = listOfReports?.filter(
      ({ ReportCategory, ReportName }) =>
        !ReportName.toLowerCase().includes('end of day') &&
        !ReportCategory.toLowerCase().includes('end of day') &&
        !ReportName.toLowerCase().includes('end of month') &&
        !ReportCategory.toLowerCase().includes('end of month') &&
        !ReportName.toLowerCase().includes('cultivation') &&
        !ReportCategory.toLowerCase().includes('cultivation')
    );
    const dropdownSmbDistributorOptions = _.map(filteredDistributorListOfReports, (report) => ({
      label: report.ReportName,
      id: getNavStringFromCategoryAndCname({
        category: report.ReportCategory,
        canonicalName: report.canonicalName,
        inventoryPerformanceFlag: inventoryNextGen,
      }),
    }));
    smbOptions = _.uniqBy(dropdownSmbDistributorOptions, 'id');
  }
  if (isCultivator || isManufacturer) {
    const filteredCultivatorListOfReports = listOfReports?.filter(
      ({ ReportCategory, ReportName }) =>
        !ReportName.toLowerCase().includes('end of day') &&
        !ReportCategory.toLowerCase().includes('end of day') &&
        !ReportName.toLowerCase().includes('end of month') &&
        !ReportCategory.toLowerCase().includes('end of month') &&
        !ReportName.toLowerCase().includes('point of sale') &&
        !ReportCategory.toLowerCase().includes('point of sale') &&
        !ReportName.toLowerCase().includes('pos') &&
        !ReportCategory.toLowerCase().includes('pos') &&
        !ReportName.toLowerCase().includes('ecomm') &&
        !ReportCategory.toLowerCase().includes('ecomm') &&
        !ReportName.toLowerCase().includes('delivery') &&
        !ReportCategory.toLowerCase().includes('delivery')
    );
    const dropdownSmbCultivatorOptions = _.map(filteredCultivatorListOfReports, (report) => ({
      label: report.ReportName,
      id: getNavStringFromCategoryAndCname({
        category: report.ReportCategory,
        canonicalName: report.canonicalName,
        inventoryPerformanceFlag: inventoryNextGen,
      }),
    }));
    smbOptions = _.uniqBy(dropdownSmbCultivatorOptions, 'id');
  }
  return smbOptions;
}

type ExportFormParams = {
  report: StaticReport;
  reportSettings: RunReportSettings;
  setting: string;
  user: UserState;
};
export async function exportForm({ report, reportSettings, user, setting }: ExportFormParams) {
  const resp = await HttpClient.post(
    report.ExportEndpoint,
    {
      SubmittedBy: user.FullName,
      ReportId: report.ReportId,
      FromDate: format(reportSettings.FromDate, 'MM/dd/yyyy h:mm a'),
      ToDate: format(reportSettings.ToDate, 'MM/dd/yyyy h:mm a'),
      ExportDate: format(new Date(), 'MM/dd/yyyy h:mm a'),
      runForAllLocations: reportSettings.runForAllLocations,
      Parameters: reportSettings.Parameters,
      OrderType: reportSettings.OrderType,
      CcrsReset: reportSettings.ccrsReset,
      partialFile: reportSettings.partialFile,
      setting,
    },
    'Report failed to generate'
  );

  if (resp) {
    for (const file of resp) {
      Utils.openFileInWindow(file.File, file.FileName, file.FileType);
    }
  } else {
    throw new Error('Error generating report');
  }
}

type DownloadReportParams = {
  excelFormat: boolean;
  file: string;
  fileName: string;
};

export async function downloadReport({ file, fileName, excelFormat }: DownloadReportParams) {
  if (excelFormat) {
    try {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([file]));
      link.download = `${fileName}.xlsx`;
      link.target = '_blank';

      link.click();
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      const binaryString = window.atob(file);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // convert bytes JSON to CSV
      const jsonString = Buffer.from(bytes).toString('utf8');
      const parsedData = JSON.parse(jsonString);

      if (!parsedData.summary) {
        console.error('No report data found in downloaded file.');
      }

      const csv = await json2csv(parsedData.summary ?? {});

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([csv], { type: 'application/text' }));
      link.download = `${fileName}.csv`;
      link.target = '_blank';
      link.click();
    } catch (error) {
      console.error(error);
    }
  }
}
