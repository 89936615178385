import { useEffect, useState } from 'react';

import uuid from 'uuid/v1';

import { useNotificationActions } from '../state/notifications';

export type Rule = {
  Attribute: string | '';
  Condition: string | '';
  Id?: string;
  Operator: string;
  SecondValue?: string;
  Value: string;
  Variant?: string;
  // SecondOperator?: string;
};

export type RuleGroup = {
  combinator: string;
  id?: string;
  rules: Rule[];
};

type QueryBuilderProps = {
  segmentDetails?: RuleGroup;
};

type UseQueryBuilder = {
  addRule: () => void;
  error: string | null;
  exportQuery: () => void;
  removeRule: (id: string) => void;
  resetQuery: () => void;
  ruleGroup: RuleGroup;
  updateCombinator: (combinator: string) => void;
  updateRule: (id: string, newValues: Partial<Rule>) => void;
};

/**
 * This hook manages a dynamic rule group for building complex queries, providing functionalities for adding, updating, and removing rules, as well as handling combinators.
 * @param {RuleGroup} [props.segmentDetails] - An optional initial set of rules and combinator to start with.
 * @returns {Object} An object with methods and state for managing the rule group:
 *   - ruleGroup: The current state of the rule group.
 *   - addRule: Adds a new rule to the group, ensuring the rule limit (5) is not exceeded.
 *   - removeRule: Removes a rule from the group by its ID.
 *   - updateRule: Updates specific attributes of a rule by its ID.
 *   - updateCombinator: Changes the combinator used to combine rules within the group.
 *   - exportQuery: Exports the current state of the rule group as a JSON string.
 *   - resetQuery: Resets the rule group to its initial state.
 *   - error: A string containing an error message.
 */

const useQueryBuilder = ({ segmentDetails }: QueryBuilderProps): UseQueryBuilder => {
  // start with a default
  const [ruleGroup, setRuleGroup] = useState<RuleGroup>({
    combinator: 'and',
    rules: [
      {
        Id: uuid(),
        Condition: '',
        Attribute: '',
        Operator: '',
        Value: '',
        SecondValue: '',
        Variant: '',
      },
    ],
  });
  const notifications = useNotificationActions();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (segmentDetails) {
      setRuleGroup(segmentDetails);
    }
  }, [segmentDetails]);

  const updateCombinator = (newCombinator: string) => {
    setRuleGroup({
      ...ruleGroup,
      combinator: newCombinator,
    });
  };

  const addRule = () => {
    // TODO: this is just an initial step towards validation and safety
    // this validation should be fleshed out more with more specific cases accounted for
    if (ruleGroup.rules.length >= 5) {
      const errorMessage = 'Cannot add more than 5 rules.';
      setError(errorMessage);
      notifications.alert({ message: errorMessage });
      return;
    }

    const newRule: Rule = {
      Id: uuid(),
      Condition: '',
      Attribute: '',
      Operator: '',
      Value: '',
      SecondValue: '',
    };
    setRuleGroup({
      ...ruleGroup,
      rules: [...ruleGroup.rules, newRule],
    });
  };

  const removeRule = (id: string) => {
    setRuleGroup({
      ...ruleGroup,
      rules: ruleGroup.rules.filter((rule) => rule.Id !== id),
    });
  };

  const updateRule = (id: string, newValues: Partial<Rule>) => {
    setRuleGroup({
      ...ruleGroup,
      rules: ruleGroup.rules.map((rule) => (rule.Id === id ? { ...rule, ...newValues } : rule)),
    });
  };

  const exportQuery = () => {
    console.log(JSON.stringify(ruleGroup));
  };

  const resetQuery = () => {
    setRuleGroup({ combinator: 'and', rules: [] });
  };

  return {
    ruleGroup,
    updateCombinator,
    addRule,
    removeRule,
    updateRule,
    exportQuery,
    resetQuery,
    error,
  };
};

export default useQueryBuilder;
