import { useCallback, useMemo } from 'react';

import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Permissions, SuperUserPermissions } from 'src/app_deprecated/constants/PermissionsConstants';
import UserStore from 'src/app_deprecated/stores/UserStore';

import { routes } from 'src/app/routes';
import { userDispensariesAtom } from 'src/app/state/user-dispensaries';
import { userPermissionsAtom } from 'src/app/state/user-permissions';
import { isFloridaLoc } from 'src/app/utils/locale';

import { LD } from '../constants/launch-darkly-flags';

import { useLDFlag } from './use-ld-flag';
import useSmbPackage from './use-smb-package';

export type BohLinkSubNavItem = {
  isNew?: boolean;
  label: string;
  pastNames?: string[];
  to: string;
  visible: boolean;
};

export type BohLink = {
  isNew?: boolean;
  label: string;
  pastNames?: string[]; // a list of 1. past names of the page (e.g. "product master" is a past name of Catalog), and 2. past names that live on a nested tab within the page (e.g. most Configure sections)
  subNavItems?: BohLinkSubNavItem[];
  to?: string;
  visible: boolean;
};

export function useBohNav(): BohLink[] {
  const { perms, superuserPerms } = useRecoilValue(userPermissionsAtom);
  const user = useRecoilValue(userDispensariesAtom);

  /* SMB filtering */
  // shows a more relevant subset of the nav depending on the business type (Retail, Cultivator, etc).
  const shouldFilterNavForSMB = useLDFlag(LD.SMALL_BUSINESS_PACKS, false);
  const financialsTabRollout = useLDFlag(LD.FINANCIALS_TAB_ROLLOUT, false);
  const mlrsPermissionRollout = useLDFlag(LD.GENERIPAY_WEDGE, false);
  const asyncReportsRollout = useLDFlag(LD.ASYNC_REPORTS_ROLLOUT, false);
  const customersProjectChunkRollout = useLDFlag(LD.CUSTOMERS_PROJECT_CHUNK_ROLLOUT, false);
  const cultivationRefactorRollout = useLDFlag(LD.CULTIVATION_REFACTOR_ROLLOUT, false);
  const moveMetrcToolRollout = useLDFlag(LD.MOVE_METRC_PULL_TOOL_ROLLOUT, false);
  const billOfMaterialsRollout = useLDFlag(LD.BILL_OF_MATERIALS_ROLLOUT, false);
  const projectChunkCampaignsRollout = useLDFlag(LD.PROJECT_CHUNK_CAMPAIGNS, false);
  const mlsrNewBadgeRollout = useLDFlag(LD.MLSR_NEW_BADGE, false);
  const receiptsNewBadgeRollout = useLDFlag(LD.SETTINGS_RECEIPTS_NEW_BADGE, false);
  const idScanNewBadgeRollout = useLDFlag(LD.SETTINGS_IDSCAN_NEW_BADGE, false);
  const inventoryPerfNewBadgeRollout = useLDFlag(LD.INVENTORY_PERF_NEW_BADGE, false);
  const auditsNewBadgeRollout = useLDFlag(LD.PRODUCTS_AUDITS_NEW_BADGE, false);

  const { isRetail, isDelivery, isManufacturer, isCultivator, isDistribution } = useSmbPackage(user.selectedLocation);
  const checkSMBVisibility = useCallback(
    (baseBool: boolean, smbBool: boolean) => {
      if (shouldFilterNavForSMB) {
        // flag is on! Check baseline bool (which may include perms, etc) + SMB bool
        return baseBool && smbBool;
      }
      // flag is off. Just use the baseline bool
      return baseBool;
    },
    [shouldFilterNavForSMB]
  );
  const metrcIntegration = Boolean(
    user.locProfile.UseMETRC && (perms[Permissions.ViewMETRCIntegration] || perms[Permissions.EditMETRCIntegration])
  );
  const bioTrackIntegration = Boolean(
    user.locProfile.UseBioTrack &&
      (perms[Permissions.ViewBioTrackIntegration] || perms[Permissions.EditBioTrackIntegration])
  );
  const usingBioTrackV4 = Boolean(user.locProfile.UseBioTrack && isFloridaLoc({ user }));
  const mmurIntegration = Boolean(
    user.locProfile.UseMMUR &&
      (perms[Permissions.ViewMMURIntegrationConfiguration] || perms[Permissions.EditMMURIntegrationConfiguration])
  );

  const metrcBatchMode = !!perms[Permissions.METRCBatchMode];

  const location = useLocation();
  const isOrgManagementRoute = location.pathname.includes('/organization');

  const links: BohLink[] = useMemo(() => {
    const links: BohLink[] = [
      {
        label: 'Home',
        to: '/home',
        visible: checkSMBVisibility(true, isRetail && !isOrgManagementRoute),
      },
      {
        label: 'Products',
        subNavItems: [
          {
            label: 'Inventory',
            to: routes.products.inventory.index,
            visible: !!perms[Permissions.ViewInventory],
            pastNames: ['receive inventory', 'receive history'],
          },
          {
            label: 'Catalog',
            to: routes.products.catalog.index,
            visible: !!perms[Permissions.ViewProductMaster],
            pastNames: ['product master'],
          },
          {
            label: 'Manifests',
            to: routes.products.manifests.index,
            visible: !!(perms[Permissions.ViewManifest] || perms[Permissions.EditManifest]),
          },
          {
            label: 'Purchase orders',
            to: routes.products.purchaseOrders.index,
            visible: user.locProfile.HasPurchasOrders && !!perms[Permissions.ReceiveInventory],
            pastNames: ['pending purchase orders'],
          },
          {
            label: 'Orders',
            to: routes.products.orders,
            visible:
              ((user.locProfile.UseOrderFulfill || user.locProfile.NoDoublePreOrder) &&
                !!perms[Permissions.ViewManifest]) ||
              (user.locProfile.UseOrderFulfill && user.locProfile.OrdersbyProduct && !!perms[Permissions.ViewManifest]),
          },
          {
            label: 'Audits',
            to: routes.products.audits.index,
            visible: !!perms[Permissions.ViewReconciliation],
            pastNames: ['inventory reconciliation'],
            isNew: auditsNewBadgeRollout,
          },
          {
            label: 'Journal',
            to: routes.products.journal,
            visible: !!perms[Permissions.ViewInventoryHistory],
          },
          {
            label: 'Vendors',
            to: routes.products.vendors,
            visible: !!(perms[Permissions.EditVendors] || perms[Permissions.ViewVendors]),
          },
          {
            label: 'Manufacturers',
            to: routes.products.manufacturers,
            visible:
              user.locProfile.UseManufacturers &&
              !!(perms[Permissions.EditProducers] || perms[Permissions.ViewProducers]),
          },
          {
            label: 'Brands',
            to: routes.products.brands,
            visible: !!(perms[Permissions.EditBrands] || perms[Permissions.ViewBrands]),
          },
          {
            label: 'Strains',
            to: routes.products.strains,
            visible: !!(perms[Permissions.EditStrains] || perms[Permissions.ViewStrains]),
          },
          {
            label: 'Configure',
            to: routes.products.configure.index,
            visible:
              !!perms[Permissions.EditProductCategories] ||
              !!perms[Permissions.ViewProductCategories] ||
              !!perms[Permissions.EditPricing] ||
              !!perms[Permissions.ViewPricing] ||
              !!perms[Permissions.EditSizes] ||
              !!perms[Permissions.ViewSizes] ||
              !!perms[Permissions.EditTags] ||
              !!perms[Permissions.CreateTags] ||
              !!perms[Permissions.DeleteTags] ||
              (!!perms[Permissions.Administrator] && user.locProfile.FormValidation) ||
              !!perms[Permissions.EditInventoryStatus] ||
              !!perms[Permissions.ViewInventoryStatus] ||
              (!user.locProfile.UseBioTrack &&
                !user.locProfile.UseMETRC &&
                (!!perms[Permissions.EditInventoryStatus] || !!perms[Permissions.ViewInventoryStatus])) ||
              (user.locProfile.EcomV2 && !!perms[Permissions.ViewProductMaster]) ||
              !!perms[Permissions.ViewDosages] ||
              !!perms[Permissions.EditDosages] ||
              !!perms[Permissions.EditPackageIdFormat] ||
              !!perms[Permissions.ViewPackageIdentityFormat] ||
              !!perms[Permissions.EditProductSkuFormat] ||
              !!perms[Permissions.ViewProductSkuFormat] ||
              !!perms[Permissions.EditBatchIdFormat] ||
              !!perms[Permissions.ViewBatchIdFormat] ||
              !!perms[Permissions.EditDeliveryTitleFormat] ||
              !!perms[Permissions.ViewDeliveryTitleFormat],
            pastNames: [
              'adjustment reasons',
              'dosages',
              'fields',
              'form validation',
              'identifiers',
              'inventory status',
              'pricing',
              'product categories',
              'product groups',
              'sizes',
              'tags',
            ],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882290236179-Products',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(true, isRetail && !isOrgManagementRoute),
      },
      {
        label: 'Financials',
        to: routes.financials.index,
        isNew: mlsrNewBadgeRollout,
        visible:
          checkSMBVisibility(financialsTabRollout, isRetail && !isOrgManagementRoute) &&
          !!perms[mlrsPermissionRollout ? Permissions.ViewMLSR : Permissions.ViewClosingReport],
      },
      {
        label: 'Reports',
        subNavItems: [
          {
            label: 'Overview',
            to: routes.reportsV2.overview.index,
            visible: !!perms[Permissions.ViewDashboard],
          },
          {
            label: 'Closing report',
            to: routes.reportsV2.closingReport.index,
            visible: !!perms[Permissions.ViewClosingReport],
          },
          {
            label: 'Sales',
            to: routes.reportsV2.sales,
            visible: !!perms[Permissions.ViewSalesReports],
          },
          {
            label: 'Inventory',
            to: routes.reportsV2.inventory.index,
            visible: !!perms[Permissions.ViewInventoryReports],
            isNew: inventoryPerfNewBadgeRollout,
          },
          {
            label: 'Employee',
            to: routes.reportsV2.employee,
            visible: !!perms[Permissions.ViewEmployeeReports],
          },
          {
            label: 'Marketing',
            to: routes.reportsV2.marketing,
            visible: !!perms[Permissions.ViewMarketingReports],
          },
          {
            label: 'Operations',
            to: routes.reportsV2.operations,
            visible: !!perms[Permissions.ViewOperationsReports],
          },
          {
            label: 'BI tools',
            to: routes.reportsV2.biTools.index,
            visible:
              user.locProfile.HasLooker && (!!perms[Permissions.ViewBIDashboard] || !!perms[Permissions.ExploreBIData]),
          },
          {
            label: 'Queued reports',
            to: routes.reportsV2.asyncReports,
            visible: !!asyncReportsRollout,
          },
          {
            label: 'Configure',
            to: routes.reportsV2.configure,
            visible: !!perms[Permissions.ViewStaticReports] && !!perms[Permissions.ScheduleEmailReports],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882401045523-Reports',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(true, isRetail && !isOrgManagementRoute),
      },
      {
        label: 'Customers',
        subNavItems: [
          {
            label: 'Customers',
            to: routes.customers.customers,
            visible: !!perms[Permissions.ViewCustomers],
          },
          {
            label: 'Customer groups',
            to: routes.customers.customerGroups,
            visible: !!perms[Permissions.EditDiscountGroups] || !!perms[Permissions.ViewDiscountGroups],
            pastNames: ['discount groups'],
          },
          {
            label: 'Segments',
            to: routes.customers.segments.index,
            visible: customersProjectChunkRollout, // TODO: add permission check once Segment perms exist
          },
          {
            label: 'Configure',
            to: routes.customers.configure,
            visible:
              !!perms[Permissions.EditDoctors] ||
              !!perms[Permissions.ViewDoctors] ||
              (!!perms[Permissions.Administrator] &&
                (!UserStore.isFieldHidden('Customer profile (Register)', 'PrimaryQualifyingConditionId') ||
                  !UserStore.isFieldHidden('Customer profile (back office)', 'PrimaryQualifyingConditionId'))) ||
              (!!perms[Permissions.Administrator] && user.locProfile.FormValidation),
            pastNames: ['doctors', 'qualifying conditions', 'form validation', 'fields'],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882503114515-Customers',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(true, (isRetail || isDistribution) && !isOrgManagementRoute),
      },
      {
        label: 'Registers',
        subNavItems: [
          {
            label: 'Overview',
            to: routes.registers.overview,
            visible: !!perms[Permissions.ViewPOSSummary],
            pastNames: [
              'bulk register transactions',
              'close out',
              'drop cash',
              'launch pos',
              'pos daily activities summary',
              'pos terminal',
            ],
          },
          {
            label: 'Transactions',
            to: routes.registers.transactions.index,
            visible: !!perms[Permissions.ViewRegisterTransactionData],
            pastNames: [
              'pos daily activities',
              'bulk register transactions',
              'drop cash',
              'close out',
              'register transactions',
            ],
          },
          {
            label: 'Configure',
            to: routes.registers.configure.index,
            visible:
              !!user.locProfile.POSStatus ||
              !!perms[Permissions.EditTransactionAdjustmentReasons] ||
              !!perms[Permissions.EditReturnReasons] ||
              !!perms[Permissions.ViewReturnReasons] ||
              user.locProfile.CustomerVisitReason ||
              !!perms[Permissions.Administrator],
            pastNames: [
              'cancellation reasons',
              'cards',
              'guestlist status',
              'order workflow',
              'pos layout',
              'register adjustments',
              'return reasons',
              'transaction adjustment reasons',
              'void reasons',
            ],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882501190675-Registers',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(
          true,
          isRetail && !isDistribution && !isCultivator && !isManufacturer && !isOrgManagementRoute
        ),
      },
      {
        label: 'Marketing',
        subNavItems: [
          {
            label: 'Discounts',
            to: routes.marketing.discounts.index,
            visible: !!(perms[Permissions.EditDiscounts] || perms[Permissions.ViewDiscounts]),
          },
          {
            label: 'Loyalty',
            to: routes.marketing.loyalty.settings,
            visible: !!perms[Permissions.EditLoyaltyAccrual] || !!perms[Permissions.ViewLoyaltyAccrual],
            pastNames: ['adjust loyalty reasons'],
          },
          {
            label: 'External loyalty',
            to: routes.marketing.externalLoyalty.index,
            visible: user.locProfile.ShowExternalLoyalty && !!perms[Permissions.ConfigureExternalLoyalty],
          },
          {
            label: 'Campaigns',
            to: routes.marketing.campaigns.index,
            visible: projectChunkCampaignsRollout && !!perms[Permissions.AccessEmailMarketing],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882550913043-Marketing',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(
          true,
          isRetail && !isDistribution && !isCultivator && !isManufacturer && !isOrgManagementRoute
        ),
      },
      {
        label: 'Delivery',
        subNavItems: [
          {
            label: 'Configure',
            to: routes.delivery.configure.index,
            visible:
              ((user.locProfile.EnableDelivery || user.locProfile.WholesaleCarsAndDrivers) &&
                !!perms[Permissions.EditCars]) ||
              !!perms[Permissions.ViewCars] ||
              !!perms[Permissions.EditDrivers] ||
              !!perms[Permissions.ViewDrivers] ||
              !!perms[Permissions.EditZones] ||
              !!perms[Permissions.ViewZones] ||
              !!perms[Permissions.EditThresholds] ||
              !!perms[Permissions.ViewThresholds] ||
              !!perms[Permissions.EditDeliveryTitleFormat] ||
              !!perms[Permissions.ViewDeliveryTitleFormat],
            pastNames: ['delivery management', 'cars', 'drivers', 'zones', 'thresholds', 'delivery title format'],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882649521043-Delivery',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(
          user.locProfile.EnableDelivery || user.locProfile.WholesaleCarsAndDrivers,
          isRetail && !isOrgManagementRoute
        ),
      },
      {
        label: 'Cultivation',
        subNavItems: [
          {
            label: 'API queue',
            to: routes.cultivation.cultivationApiQueue,
            visible:
              user.locProfile.APIActionsQueued &&
              (!!perms[Permissions.ViewCultivationApiQueue] || !!perms[Permissions.EditCultivationApiQueue]),
          },
          {
            label: 'Plants',
            to: routes.cultivation.plants.index,
            visible: !!perms[Permissions.ViewPlants],
            pastNames: [
              'plant manager',
              'plant group manager',
              'scan plants into location',
              'scan plants into harvest',
              'scan plants into plant rooms',
              'scan plants into retirement',
              'move plant subrooms into plant rooms',
              'add plant/clones to inventory',
            ],
          },
          {
            label: 'Plant groups',
            to: routes.cultivation.plantGroups,
            visible: !!perms[Permissions.ViewPlantGroups] && cultivationRefactorRollout, // Only show the separate Plant groups nav item when the refactor flag is enabled
          },
          {
            label: 'Batches',
            to: routes.cultivation.batches,
            visible: !!perms[Permissions.ViewPlantGroups],
            pastNames: ['batch manager'],
          },
          {
            label: 'Harvests',
            to: routes.cultivation.harvests,
            visible: !!perms[Permissions.ViewPlantGroups],
            pastNames: ['harvest efficiency'],
          },
          {
            label: 'Waste',
            to: routes.cultivation.waste,
            visible: !!perms[Permissions.ViewWasteManager],
            pastNames: ['waste manager'],
          },
          {
            label: 'Audits',
            to: routes.cultivation.audits,
            visible: user.locProfile.PlantReconciliation,
            pastNames: ['cultivation audits', 'plant reconciliation'],
          },
          {
            label: 'Configure',
            to: routes.cultivation.configure.index,
            visible: !!perms[Permissions.EditReasonCodes] || !!perms[Permissions.ViewReasonCodes],
            pastNames: ['cultivation adjustments', 'stages'],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882746201619-Cultivation',
            visible: true,
          },
        ],
        // TODO: check with Heather on this one. Should Cultivation be conditional based on isCultivator?
        visible: checkSMBVisibility(!!user.locProfile.IsLicensed, isCultivator && !isOrgManagementRoute),
      },
      {
        label: 'Manufacturing',
        subNavItems: [
          {
            label: 'Recipes',
            to: routes.manufacturing.recipes,
            visible:
              !!perms[Permissions.ViewRecipes] ||
              !!perms[Permissions.ViewRecipeBatch] ||
              !!perms[Permissions.EditRecipe],
            pastNames: ['recipe batches'],
          },
          {
            label: 'Bill of materials',
            to: routes.manufacturing.billOfMaterials,
            visible: billOfMaterialsRollout,
            // visible: Add the following once these permissions are created
            //   !!perms[Permissions.ViewBillOfMaterials] ||
            //   !!perms[Permissions.EditBillOfMaterials],
          },
          {
            label: 'Infusions',
            to: routes.manufacturing.infusions,
            visible: user.locProfile.UseInfusions && !!perms[Permissions.ViewInfusions],
            pastNames: ['infusion batches'],
          },
          {
            label: 'Distillations',
            to: routes.manufacturing.distillations,
            visible:
              (perms[Permissions.EditDistillations] || perms[Permissions.ViewDistillations]) &&
              user.locProfile.UseDistillationMethod,
            pastNames: ['distillations'],
          },
        ],
        visible: checkSMBVisibility(
          user.locProfile.NewIAManufacturingModule &&
            (!!perms[Permissions.ViewRecipes] ||
              !!perms[Permissions.ViewRecipeBatch] ||
              !!perms[Permissions.EditRecipe]),
          ((isDistribution && isDelivery) || isManufacturer) && !isOrgManagementRoute
        ),
      },
      {
        label: 'Traceability',
        subNavItems: [
          {
            label: 'Integration audit',
            to: routes.traceability.integrationAudit,
            visible:
              !!perms[Permissions.ViewIntegrationReconciliation] &&
              (user.locProfile.UseMETRC || user.locProfile.UseBioTrack),
            pastNames: ['integration reconciliation'],
          },
          {
            label: 'Receipt audit',
            to: routes.traceability.receiptAudit,
            visible: !!perms[Permissions.ViewReceiptAudit] && (user.locProfile.UseMETRC || usingBioTrackV4),
            pastNames: ['receipt reconciliation'],
          },
          {
            label: 'Cultivation integration audit',
            to: routes.traceability.cultivationIntegrationAudit,
            visible:
              user.locProfile.IsLicensed &&
              !!perms[Permissions.ViewIntegrationReconciliation] &&
              (user.locProfile.UseMETRC || user.locProfile.UseBioTrack) &&
              user.locProfile.ShowCultivationIntegrationReconcilation,
            pastNames: ['integration reconciliation'],
          },
          {
            label: 'Logs',
            to: routes.traceability.logs.index,
            visible: metrcIntegration || metrcBatchMode || mmurIntegration || bioTrackIntegration,
            pastNames: ['metrc', 'batch mode logs'],
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882744815763-Traceability',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(true, (isRetail || isDistribution) && !isOrgManagementRoute),
      },
      {
        label: 'Settings',
        subNavItems: [
          {
            label: 'Account',
            to: routes.settings.account,
            visible: true,
          },
          {
            label: 'Location',
            to: routes.settings.location,
            visible: !!perms[Permissions.ViewPasswordSettings] || !!perms[Permissions.Administrator],
            pastNames: ['location options', 'password settings', 'security'],
          },
          {
            label: 'Users',
            to: routes.settings.users.index,
            visible:
              !!perms[Permissions.EditUsers] ||
              !!perms[Permissions.ViewUsers] ||
              !!perms[Permissions.EditUserStateIds] ||
              !!perms[Permissions.ViewPermissions],
            pastNames: ['user permission groups'],
          },
          {
            label: 'Devices',
            to: routes.settings.devices,
            visible: !!perms[Permissions.EditRegisters] || !!perms[Permissions.ViewRegisters],
            pastNames: ['pos maintenance registers', 'printers'],
          },
          {
            label: 'Receipts',
            to: routes.settings.receipts,
            isNew: receiptsNewBadgeRollout,
            visible: !!perms[Permissions.ConfigureReceipts],
          },
          {
            label: 'Rooms',
            to: routes.settings.rooms.index,
            visible:
              !!perms[Permissions.ViewRooms] ||
              !!perms[Permissions.EditRooms] ||
              !!perms[Permissions.ViewTables] ||
              !!perms[Permissions.EditTables],
            pastNames: ['subrooms', 'tables'],
          },
          {
            label: 'Labels',
            to: routes.settings.labels,
            visible: !!perms[Permissions.ConfigureLabels],
            pastNames: ['label library', 'image library'],
          },
          {
            label: 'Taxes & compliance',
            to: routes.settings.taxesAndCompliance.index,
            visible: true, // no permission key found in `src/app_deprecated/constants/PermissionsConstants`
            pastNames: ['customer views audit records', 'purchase limits'],
          },
          {
            label: 'Fees & donations',
            to: routes.settings.feesDonations,
            visible:
              user.locProfile.UseFeesDonations &&
              (!!perms[Permissions.EditFeesDonations] || !!perms[Permissions.ViewFeesAndDonations]),
            pastNames: ['fees and donations'],
          },
          {
            label: 'Integrations',
            to: routes.settings.integrations.index,
            visible: true,
            isNew: idScanNewBadgeRollout,
            pastNames: [
              'metrc',
              'biotrack',
              'canpay',
              'adilas',
              'mmur',
              'pmp upload',
              'sso',
              'masscip',
              'weed maps 2',
              'linx',
              'cashless atm',
              'leafly',
              'leaflink',
              'spin payments',
              'leafpay',
              'biomauris',
              'spring big',
              'alpine iq',
              'fyllo',
              'hypur',
              'quickbooks',
              'mn omc',
              'onfleet',
              'tookan',
              'workwave',
              'leaf data',
              'ccrs',
              'idscan',
              'sprout configuration',
            ],
          },
          {
            label: 'Time clock',
            to: routes.settings.timeClock,
            visible: true,
          },
          {
            label: 'Help center',
            to: 'https://support.dutchie.com/hc/en-us/sections/12882796400531-Settings',
            visible: true,
          },
        ],
        visible: checkSMBVisibility(true, isRetail && !isOrgManagementRoute),
      },
      {
        label: 'Superuser',
        subNavItems: [
          {
            label: 'Superuser tools',
            to: routes.superuser.tools,
            visible: true,
          },
          {
            label: 'Superuser logs',
            to: routes.superuser.logs,
            visible: true,
          },
          {
            label: 'Superuser management',
            to: routes.superuser.management,
            visible: !!superuserPerms[SuperUserPermissions.ManageSuperUsers],
          },
          {
            label: 'SSO global config',
            to: routes.superuser.ssoGlobalconfig,
            visible: true,
          },
          {
            label: 'Manifest options',
            to: routes.superuser.manifestOptions,
            visible: true,
          },
          {
            label: 'Audit tables',
            to: routes.superuser.auditTables,
            visible: true,
          },
          {
            label: 'Upload provincial catalog',
            to: routes.superuser.provincialCatalogUpload,
            visible: true,
          },
          {
            label: 'METRC tools',
            to: routes.superuser.metrcTools,
            visible: !moveMetrcToolRollout,
          },
          {
            label: 'Feature flags',
            to: routes.superuser.features,
            visible: true,
          },
          {
            label: 'Edit company',
            to: routes.superuser.editLsp,
            visible: true,
          },
          {
            label: 'Create company',
            to: routes.superuser.createLsp,
            visible: !!superuserPerms[SuperUserPermissions.AccountCreation],
          },
          {
            label: 'Public API',
            to: routes.superuser.publicApi,
            visible: true,
          },
          {
            label: 'Looker',
            to: routes.superuser.lookerAdmin,
            visible: true,
          },
          {
            label: 'Documentation',
            to: routes.superuser.documentation,
            visible: true,
          },
          {
            label: 'Bulk update',
            to: routes.superuser.bulkUpdateData,
            visible: true,
          },
          {
            label: 'State packs',
            to: routes.superuser.licenseTypes,
            visible: !!superuserPerms[SuperUserPermissions.StatePackModification],
          },
          {
            label: 'Data loader',
            to: '/superuser/data-import',
            visible: !user.selectedLocation.IsLive && !!superuserPerms[SuperUserPermissions.AccessDataImport],
          },
          {
            label: 'Data wipe',
            to: routes.superuser.dataWipe,
            visible: !user.selectedLocation.IsLive && !!superuserPerms[SuperUserPermissions.AccessDataWipe],
          },
          {
            label: 'Publish location',
            to: routes.superuser.publishLocation,
            visible:
              (!user.selectedLocation.IsLive && !!superuserPerms[SuperUserPermissions.PublishLocation]) ||
              (user.selectedLocation.IsLive && !!superuserPerms[SuperUserPermissions.RevertToStaging]),
          },
          {
            label: 'Location manager',
            to: routes.superuser.locationManager,
            visible: !!superuserPerms[SuperUserPermissions.AccessLocationManager],
          },
        ],
        visible: checkSMBVisibility(!!perms[Permissions.SuperUser], true && !isOrgManagementRoute),
      },
    ];

    const localizeLabelsDFS = (links: BohLink[]) => {
      links.forEach((link) => {
        link.label = i18next.t(link.label);
        if (link.subNavItems) {
          localizeLabelsDFS(link.subNavItems);
        }
      });
    };

    localizeLabelsDFS(links);

    return links;
  }, [
    checkSMBVisibility,
    isRetail,
    perms,
    user.locProfile,
    user.selectedLocation.IsLive,
    financialsTabRollout,
    mlrsPermissionRollout,
    asyncReportsRollout,
    customersProjectChunkRollout,
    isDistribution,
    isCultivator,
    isManufacturer,
    cultivationRefactorRollout,
    isDelivery,
    usingBioTrackV4,
    metrcIntegration,
    metrcBatchMode,
    mmurIntegration,
    bioTrackIntegration,
    superuserPerms,
    moveMetrcToolRollout,
    isOrgManagementRoute,
  ]);

  return links;
}
