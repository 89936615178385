import type { OperatorGroup } from '../details/segment-query-builder/operators';

export enum AttributeName {
  AvgOrderValue = 'avgOrderValue',
  Birthday = 'birthday',
  CustomerTypeId = 'customerTypeId',
  FirstPurchaseDate = 'firstPurchaseDate',
  GrossTotalSpend = 'grossTotalSpend',
  IsOutOfState = 'isOutOfState',
  LastPurchaseDate = 'lastPurchaseDate',
  MedicalExpirationDate = 'medicalExpirationDate',
  MostCommonlyBoughtCategory = 'mostCommonlyBoughtCategory',
  State = 'state',
}

export enum ConditionName {
  AttributesOfSomeone = 'attributesOfSomeone',
  PredictiveAnalyticsAboutSomeone = 'predictiveAnalyticsAboutSomeone',
  WhatSomeoneHasOrHasNotDone = 'whatSomeoneHasOrHasNotDone',
}

export type Variant = {
  id: string;
  label: string;
};

export type Attributes = {
  label: string;
  name: string;
  operatorGroup: OperatorGroup;
  variants?: Variant[];
};

export type CustomerSegmentSchema = {
  attributes: Attributes[];
  id?: string;
  label: string;
  value: string;
};

const predictiveAnalyticsAboutSomeoneCriteria: Attributes[] = [
  { name: AttributeName.AvgOrderValue, label: 'Average order value', operatorGroup: 'percent' },
  { name: AttributeName.GrossTotalSpend, label: 'Gross total spend', operatorGroup: 'percent' },
];

const whatSomeoneHasOrHasNotDoneCriteria: Attributes[] = [
  {
    name: AttributeName.MostCommonlyBoughtCategory,
    label: 'Most commonly bought category',
    operatorGroup: 'productCategory',
  },
  { name: AttributeName.FirstPurchaseDate, label: 'First purchase date', operatorGroup: 'historicDate' },
  { name: AttributeName.LastPurchaseDate, label: 'Last purchase date', operatorGroup: 'historicDate' },
];

const attributesOfSomeoneCriteria: Attributes[] = [
  { name: AttributeName.Birthday, label: 'Birthday', operatorGroup: 'date' },
  { name: AttributeName.State, label: 'State', operatorGroup: 'stateLocation' },
  { name: AttributeName.IsOutOfState, label: 'Is tourist', operatorGroup: 'boolean' },
  { name: AttributeName.MedicalExpirationDate, label: 'Medical expiration date', operatorGroup: 'date' },
  { name: AttributeName.CustomerTypeId, label: 'Customer type', operatorGroup: 'customerType' },
];

export type CustomerSegmentSchemaMap = {
  [key in ConditionName]: CustomerSegmentSchema;
};

export const customerSegmentSchema: CustomerSegmentSchemaMap = {
  [ConditionName.WhatSomeoneHasOrHasNotDone]: {
    id: 'whatSomeoneHasOrHasNotDone',
    value: 'whatSomeoneHasOrHasNotDone',
    label: 'What someone has or has not done',
    attributes: whatSomeoneHasOrHasNotDoneCriteria,
  },
  [ConditionName.AttributesOfSomeone]: {
    id: 'attributesOfSomeone',
    value: 'attributesOfSomeone',
    label: 'Attributes of someone',
    attributes: attributesOfSomeoneCriteria,
  },
  [ConditionName.PredictiveAnalyticsAboutSomeone]: {
    id: 'predictiveAnalyticsAboutSomeone',
    value: 'predictiveAnalyticsAboutSomeone',
    label: 'Predictive analytics about someone',
    attributes: predictiveAnalyticsAboutSomeoneCriteria,
  },
};
