import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Cookies from 'universal-cookie';

import AppActions from 'src/app_deprecated/actions/AppActions';

import { useNotificationActions } from 'src/app/state/notifications';

import type { AxiosResponse } from 'axios';

const EMPLOYEE_LOGIN = 'api/posv3/user/EmployeeLogin';

type LoginPayload = {
  password: string;
  username: string;
};

export type LoginResponse = {
  Data: {
    backofficeURL?: string;
    LastLoginFailure?: string;
    LoginFailureCount?: number;
    MaxLoginFailureCount?: number;
    MaxRetryMinutes?: number;
    RetryMinutes?: number;
    SessionGId: string;
    SessionId: string;
    shouldShowBlockingUI?: boolean;
    TraceId?: string;
    Url?: string;
  };
  Message: string;
  Result: boolean;
};

export type LoginMutationProps = {
  showFullPageMigrationTakeover: (props: LoginResponse['Data']) => void;
  showMaxLoginFailureModal: (props: Partial<LoginResponse['Data']>) => void;
};

export function useLoginMutation({ showFullPageMigrationTakeover, showMaxLoginFailureModal }: LoginMutationProps) {
  const notifications = useNotificationActions();
  return useMutation({
    mutationKey: ['user-login'],
    mutationFn: (payload: LoginPayload) =>
      axios.post(`/${EMPLOYEE_LOGIN}`, {
        username: payload.username,
        password: payload.password,
        url: window.location.origin,
      }),
    onSuccess: (body: AxiosResponse<LoginResponse>) => {
      const { Result, Data, Message } = body.data;

      if (!Result) {
        if (Data) {
          // user's LSP has been migrated to a different server (listed in LD config returned as Data)
          if (Data.shouldShowBlockingUI) {
            showFullPageMigrationTakeover(Data);
            return;
          }
          if ((Data.LoginFailureCount ?? 0) > (Data.MaxLoginFailureCount ?? 0)) {
            showMaxLoginFailureModal(Data);
          } else {
            notifications.error({ message: 'Invalid username or password' });
          }
        } else {
          notifications.error({ message: Message ?? 'Something has gone wrong' });
        }
      } else {
        localStorage.setItem('traceparent', Data.TraceId ?? '');
        if (Data.Url) {
          notifications.alert({ message: 'Your password has expired and must be changed' });
          setTimeout(() => {
            window.location = Data.Url as Location & string;
          }, 2000);
          return;
        }
        Data.SessionId = Data.SessionGId;
        const cookies = new Cookies();
        cookies.set('LeafLogixSessionInfo', Data, { path: '/' });

        AppActions.boot(Data);
      }
    },
    onError: (error: unknown) => {
      console.error(error);
      notifications.error({ message: 'Something went wrong' });
    },
  });
}
