import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import { checkSegmentStatusQueryKey } from './check-segment-status';
import { segmentDetailKeys } from './query-key-factory';

import type { QueryKey } from '@tanstack/react-query';
import type { RuleGroup } from 'src/app/hooks/use-query-builder';

type SaveSegmentQueryPayload = {
  Name: string;
  RuleGroup: RuleGroup;
  SegmentId: number | null;
};

export const SAVE_SEGMENT_QUERY_ENDPOINT = 'api/segment/save-segment';

export const segmentMutationQueryKey: QueryKey = ['save-segment-details'];

export const useSaveSegmentQueryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: segmentMutationQueryKey,
    mutationFn: (payload: SaveSegmentQueryPayload) =>
      postData<SaveSegmentQueryPayload, void>({
        endpoint: SAVE_SEGMENT_QUERY_ENDPOINT,
        payload: { Name: payload.Name, RuleGroup: payload.RuleGroup, SegmentId: payload.SegmentId },
      }),
    onMutate: async (payload: SaveSegmentQueryPayload) => {
      // optimistically update the query builder settings if segment exists
      if (payload.SegmentId) {
        await queryClient.cancelQueries({ queryKey: segmentDetailKeys.one(payload.SegmentId) });
        queryClient.setQueryData(segmentDetailKeys.one(payload.SegmentId), (oldData: any) => ({
          ...oldData,
          segmentDetails: payload.RuleGroup,
          segmentName: payload.Name,
        }));
      }
    },
    onSuccess: () => {
      // start polling the segment updating status
      void queryClient.invalidateQueries({ queryKey: checkSegmentStatusQueryKey });
    },
    onError: async (error, variables) => {
      // if an error occurs invalidate query
      if (variables.SegmentId) {
        await queryClient.invalidateQueries({ queryKey: segmentDetailKeys.one(variables.SegmentId) });
      }
    },
  });
};
