/**
 * Launch Darkly flags
 * @example Usage:
 * import { LD } from 'src/app/constants/launch-darkly-flags';
 * import { useLDFlag } from 'src/app/hooks/use-ld-flag';
 *
 * const thingRollout = useLDFlag(LD.THING_ROLLOUT, false);
 * or
 * const thingRollout = useLDFlag<boolean>(LD.THING_ROLLOUT);
 *
 * @function useLDFlag - src/app/hooks/use-ld-flag.ts
 *
 * Deprecation/Removal Steps:
 * 1. Add example deprecated comment above the flag you are removing.
 * 2. Right click key and select "Find All References" to find all usages.
 * 3. Remove the flag from the enum.
 *
 * @see Naming Conventions
 * https://dutchie.atlassian.net/wiki/spaces/EN/pages/19934543881/LaunchDarkly+Flag+Naming+Convention
 * `{charter}.{module/team}.{name}.{rollout|experiment|entitlement|operational}`
 */
export enum LD {
  /**
   * @deprecated Fully enabled
   */
  A_THING_ROLLOUT_DEPRECATED_EXAMPLE = 'backoffice.platform.deprecated-example.rollout',
  ASYNC_REPORTS_ROLLOUT = 'pos.backoffice.async-reports.rollout',
  BILL_OF_MATERIALS_ROLLOUT = 'backoffice.bill-of-materials-and-assemblies.rollout',
  BULK_UPDATE_CUSTOMER_PAGINATION = 'pos.backoffice.bulk-update-pagination.rollout',
  CULTIVATION_REFACTOR_ROLLOUT = 'pos.backoffice.cultivation-refactor.rollout',
  CUSTOMER_DEDUPE_DOB_CRITERIA = 'pos.backoffice.customer-dedupe-tool-dob-criteria.rollout',
  CUSTOMERS_PROJECT_CHUNK_ROLLOUT = 'pos.backoffice.customers.project-chunk.rollout',
  CUSTOMERS_QUERY_BUILDER_CHUNK_ROLLOUT = 'pos.backoffice.customers.project-chunk.query-builder.rollout',
  DEBOUNCE_CUSTOMERS = 'pos.backoffice.debounce-customers.rollout',
  DISABLE_USER_PASSWORD_LOGIN = 'sso.disable-user-password-login',
  DISPLAY_METRC_BATCH_MODE_OPTION = 'pos.traceability.display-metrc-batch-mode-option.rollout',
  ENABLE_DECAGON = 'pos.backoffice.enable-decagon.rollout',
  FINANCIALS_TAB_ROLLOUT = 'pos.backoffice.financials-tab.rollout',
  GENERAL_BACKOFFICE_HEADER_BANNER = 'backoffice.platform.general-header-banner.entitlement',
  GENERIPAY_BIRCHMOUNT = 'fintech.retail-payments.birchmount-gift-card-redemption.rollout',
  GENERIPAY_WEDGE = 'fintech.retail-payments.wedge-generipay-adapter.rollout',
  HOMEPAGE_MARKETING = 'pos.backoffice.homepage.experiment',
  INVENTORY_PERF_NEW_BADGE = 'platform.report.inventory-perf-new-badge.rollout',
  LOGIN_MARKETING = 'pos.backoffice.login.experiment',
  MANUAL_EXTERNAL_LOYALTY_DISCOUNTS = 'core.cats-pos.manual-external-loyalty-discounts.rollout',
  METRC_RETAIL_ID = 'pos.traceability.metrc-retail-id.rollout',
  MLSR_NEW_BADGE = 'platform.report.mlsr-new-badge.rollout',
  MLSR_PERMISSION_ROLLOUT = 'platform.report.MLSR-permission.rollout',
  MOVE_METRC_PULL_TOOL_ROLLOUT = 'pos.backoffice.move-metrc-pull-tool.rollout',
  NON_SANDBOX_BANNER_ALERT = 'backoffice.platform.non-sandbox-banner-alert.rollout',
  PAYMENTS_HUB_UPSELLS_POC = 'fintech.retail-payments.dutchie-hub-upsell-poc.rollout',
  PRODUCTS_AUDITS_NEW_BADGE = 'pos.backoffice.audits-new-badge.rollout',
  PROJECT_CHUNK_CAMPAIGNS = 'core.chunk-tiger-team.mailjet-campaigns.rollout',
  PUBLIC_API_KEY_BULK_ACTION_ROLLOUT = 'publicapi.superuser-bulk-actions.rollout',
  PUBLIC_API_KEY_DELETE_BUTTON_ROLLOUT = 'publicapi.superuser-delete-button.rollout',
  SEPARATE_PRINT_COMMANDS = 'pos.register.separate-print-commands.rollout',
  SERVER_MIGRATION_CONFIG = 'pos.platform.database-migration-configs.operational',
  SERVER_PAGINATED_DROPDOWN_FIX = 'backoffice.server-paginated-downdown-fix.rollout',
  SETTINGS_IDSCAN_NEW_BADGE = 'pos.backoffice.idscan-new-badge.rollout',
  SETTINGS_RECEIPTS_NEW_BADGE = 'pos.backoffice.receipts-new-badge.rollout',
  SMALL_BUSINESS_PACKS = 'pos.tools.small-business-packs',
}
