import React from 'react';

import styled from 'styled-components';

import Translate from 'src/app_deprecated/components/form/layout/Translate.react';

import { Button } from 'src/app/components/lib/button';
import { ControlGroup } from 'src/app/components/lib/control-bar';
import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { applyUuidToDataGridRows, DataGrid, generateRowActions } from 'src/app/components/lib/table';
import { useDeleteMailjetSenderMutation } from 'src/app/queries/integrations/mailjet/delete-sender';
import { useMailjetSendersQuery } from 'src/app/queries/integrations/mailjet/get-mailjet-senders';

import MailJetSender from './mailjet-sender';

export default function MailJetSenderList() {
  const { data, isLoading, refetch } = useMailjetSendersQuery();
  const deleteSenderMutation = useDeleteMailjetSenderMutation({ onSuccess: refetch, onError: refetch });
  const [selected, setSelected] = React.useState<any>(undefined);

  const handleDelete = (row: any) => {
    deleteSenderMutation.mutate({ SenderId: row.ID });
  };

  const onAdd = () => {
    setSelected({ isDefault: false, name: '', emailAddress: '' });
  };

  if (selected) {
    return (
      <MailJetSender
        sender={selected}
        onComplete={async () => {
          setSelected(undefined);
          await refetch();
        }}
      />
    );
  }

  return (
    <>
      <HeaderContainer>
        <StyledHeading>
          <Translate>Senders</Translate>
        </StyledHeading>
        <ControlGroup>
          <Button label='Create Sender' onClick={onAdd} />
        </ControlGroup>
      </HeaderContainer>
      <DataGrid
        autoHeight
        columns={[
          { field: 'Name', headerName: 'Name', width: 200 },
          { field: 'Email', headerName: 'Email', width: 200 },
          { field: 'IsDefaultSender', headerName: 'Default', width: 100, type: 'boolean' },
          { field: 'Status', headerName: 'Status', width: 100 },
          generateRowActions([{ visible: true, value: 'Delete', onClick: handleDelete }]),
        ]}
        loading={isLoading}
        name='MailJetSenderList'
        rows={applyUuidToDataGridRows(data as any)}
      />
    </>
  );
}

const StyledHeading = styled(Heading)`
  margin-bottom: -1;
`;
