import { useQuery } from '@tanstack/react-query';

import type { ProductCategory } from 'src/app_deprecated/stores/InventoryStore';

import { getSegmentByKey } from './predefined-segment-rules';
import { customerSegmentSchema } from './schema';

import type { CustomerSegmentSchemaMap } from './schema';
import type { DropdownMenuOption } from 'src/app/components/lib/dropdown';
import type { RuleGroup } from 'src/app/hooks/use-query-builder';

export type SegmentPayload = {
  segmentName: string;
};

export type QueryRulesAndSchemaResponse = {
  customerSegmentSchema: CustomerSegmentSchemaMap;
  segmentDetails?: RuleGroup;
};

// this can be used to test the query builder with the test schema and predefined segment rules
export const useGetQueryRulesAndSchema = (params: SegmentPayload) =>
  useQuery({
    queryKey: ['segment-query-builder'],
    queryFn: async () => getQueryBuilderData(params),
  });

const getQueryBuilderData = ({ segmentName }: SegmentPayload) => {
  const segmentDetails = getSegmentByKey(segmentName);

  return {
    segmentDetails,
    customerSegmentSchema,
  };
};

export const parseSelectedIds = (values: string): number[] => values.split(',').map((id) => Number(id));

export const mapToDropdownOptions = (
  selectedIds: number[],
  allProductCategoryOptions: ProductCategory[]
): DropdownMenuOption[] =>
  selectedIds
    .map((id) => {
      const option = allProductCategoryOptions.find((opt) => opt.ProductCategoryId === id);
      if (option) {
        return { label: option.ProductCategory, id: option.ProductCategoryId.toString() };
      }
      return null;
    })
    .filter((option) => option !== null);

export const getMultiSelectedOptionsForProductCategories = (
  values: string,
  allProductCategoryOptions: ProductCategory[]
): DropdownMenuOption[] => {
  const selectedIds = parseSelectedIds(values);
  return mapToDropdownOptions(selectedIds, allProductCategoryOptions);
};
