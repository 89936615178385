import { type RuleGroup } from 'src/app/hooks/use-query-builder';

import { AttributeName, ConditionName } from './schema';

export const winBackDormantShopper: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Attribute: AttributeName.LastPurchaseDate,
      Operator: '>=',
      Value: '90',
      Condition: ConditionName.WhatSomeoneHasOrHasNotDone,
    },
  ],
};

export const tourists: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.IsOutOfState,
      Operator: '=',
      Value: '1',
    },
  ],
};

export const highAOV: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.PredictiveAnalyticsAboutSomeone,
      Attribute: AttributeName.AvgOrderValue,
      Operator: 'topPercent',
      Value: '25',
      SecondValue: '90',
    },
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.State,
      Operator: '=',
      Value: 'California',
    },
  ],
};

export const bigSpender: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.PredictiveAnalyticsAboutSomeone,
      Attribute: AttributeName.GrossTotalSpend,
      Operator: 'topPercent',
      Value: '25',
      SecondValue: '90',
    },
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.State,
      Operator: '=',
      Value: 'California',
    },
  ],
};

export const upcomingBirthday: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.Birthday,
      Operator: '<=',
      Value: '30',
    },
  ],
};

export const mostCommonlyBoughtFlower: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.WhatSomeoneHasOrHasNotDone,
      Attribute: AttributeName.MostCommonlyBoughtCategory,
      Operator: '=',
      Value: '1237',
    },
  ],
};

export const mostCommonlyBoughtEdibles: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.WhatSomeoneHasOrHasNotDone,
      Attribute: AttributeName.MostCommonlyBoughtCategory,
      Operator: '=',
      Value: '2766',
    },
  ],
};

export const mostCommonlyBoughtConcentrates: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.WhatSomeoneHasOrHasNotDone,
      Attribute: AttributeName.MostCommonlyBoughtCategory,
      Operator: '=',
      Value: '2765',
    },
  ],
};

export const newCustomers: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Attribute: AttributeName.LastPurchaseDate,
      Operator: '>=',
      Value: '30',
      Condition: ConditionName.WhatSomeoneHasOrHasNotDone,
    },
  ],
};

export const medIdExpiresSoon: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.MedicalExpirationDate,
      Operator: '<=',
      Value: '90',
    },
  ],
};

export const customerTypeMedical: RuleGroup = {
  combinator: 'and',
  rules: [
    {
      Id: 'rule-1',
      Condition: ConditionName.AttributesOfSomeone,
      Attribute: AttributeName.CustomerTypeId,
      Operator: '=',
      Value: '1',
    },
  ],
};

export const ruleGroups = {
  winBackDormantShopper,
  tourists,
  highAOV,
  bigSpender,
  upcomingBirthday,
  mostCommonlyBoughtFlower,
  mostCommonlyBoughtEdibles,
  mostCommonlyBoughtConcentrates,
  newCustomers,
  medIdExpiresSoon,
  customerTypeMedical,
};

export const getSegmentByKey = (key: string): RuleGroup | undefined => ruleGroups[key];
