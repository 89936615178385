import { useQuery } from '@tanstack/react-query';

import { fetchData } from 'src/app/queries';

export const mailjetDnsQuery = ['mailjet', 'dns'];

export type MailjetDnsRecord = {
  DKIMRecordName: string;
  DKIMRecordValue: string;
  DKIMStatus: string;
  Domain: string;
  ID: number;
  IsCheckInProgress: boolean;
  LastCheckAt?: Date | null;
  OwnerShipToken: string;
  OwnerShipTokenRecordName: string;
  SPFRecordValue: string;
  SPFStatus: string;
};

export function useMailjetDomainsQuery() {
  return useQuery({
    queryKey: mailjetDnsQuery,
    queryFn: () => fetchData<MailjetDnsRecord[]>('api/mailjet/get-dns'),
    staleTime: Infinity,
  });
}
