import { atom, selector } from 'recoil';

import UserStore from '../../app_deprecated/stores/UserStore';

export type UserState = {
  Administrator?: boolean;
  Cultivation?: boolean;
  Dispensary?: boolean;
  ExportReports?: boolean;
  FullName: string;
  Id: number;
  Inspector?: boolean;
  Inventory?: boolean;
  InventoryReadOnly?: boolean;
  LocId?: number;
  LoginType?: string;
  LspId?: number;
  ManagerFlag?: boolean;
  OfficeAccess?: undefined;
  OnlineStatus?: string;
  PatientAcct?: number;
  SessionGId?: string;
  SessionId: string;
  TraceId?: null;
  UserName: string;
};

export const defaultUser: UserState = {
  Id: 0,
  SessionId: '',
  UserName: '',
  FullName: '',
};

export const userAtom = atom<UserState>({
  key: 'user',
  default: defaultUser,
  effects_UNSTABLE: [useSyncStateEffect],
});

function useSyncStateEffect({ setSelf, trigger }) {
  function changeListenerForUserState() {
    // user data is at UserStore.data
    const { data } = UserStore.getState();
    let newData = { ...data }; // Create a copy of data

    // Mutate the copy
    if (data) {
      newData.Id = Number(newData.Id ?? 0);
    } else {
      newData = false;
    }

    // Use the mutated copy
    setSelf(newData);
  }

  if (trigger === 'get') {
    changeListenerForUserState(); // initial call
  }

  // Subscribe to remote storage changes and update the atom value
  UserStore.addChangeListener(changeListenerForUserState);

  // Cleanup remote storage subscription
  return () => UserStore.removeChangeListener(changeListenerForUserState);
}

export const userIsLoggedInSelector = selector({
  key: 'userIsLoggedIn',
  get: ({ get }) => {
    const user = get(userAtom);

    // tests for this in ./state/__tests__/user.test
    // '1' > 0 => true
    // 1 > 0 => true
    // '' > 0 => false
    // undefined > 0 => false
    // null > 0 => false
    return user.Id > 0;
  },
});
