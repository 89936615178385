import React from 'react';

type EyeOpenIconProps = {
  onClick?: (e) => void;
};

export function EyeOpenIcon({ onClick }: EyeOpenIconProps): JSX.Element {
  return (
    <svg
      data-testid='eye-open-icon'
      fill='none'
      height='16'
      style={{ cursor: 'pointer' }}
      viewBox='0 0 20 16'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        clipRule='evenodd'
        d='M10.0002 4.36006C8.00163 4.36006 6.38144 5.98025 6.38144 7.97886C6.38144 9.97747 8.00163 11.5977 10.0002 11.5977C11.9989 11.5977 13.619 9.97747 13.619 7.97886C13.619 5.98025 11.9989 4.36006 10.0002 4.36006ZM7.82896 7.97886C7.82896 6.77969 8.80108 5.80758 10.0002 5.80758C11.1994 5.80758 12.1715 6.77969 12.1715 7.97886C12.1715 9.17802 11.1994 10.1501 10.0002 10.1501C8.80108 10.1501 7.82896 9.17802 7.82896 7.97886Z'
        fill='var(--color-gray-50)'
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M19.5079 6.53704C20.1636 7.39462 20.1644 8.56336 19.508 9.42057C18.768 10.3866 17.5006 11.8781 15.884 13.1341C14.2755 14.3838 12.2472 15.4577 10.0003 15.4577C7.7533 15.4577 5.72503 14.3838 4.11654 13.1341C2.50093 11.8789 1.23414 10.3885 0.493968 9.42251C0.173784 9.00941 0 8.50156 0 7.97886C0 7.45617 0.173773 6.94834 0.493938 6.53525C1.23411 5.56921 2.50091 4.07886 4.11654 2.82363C5.72503 1.57395 7.7533 0.5 10.0003 0.5C12.2472 0.5 14.2755 1.57395 15.884 2.82363C17.5006 4.07964 18.768 5.57106 19.5079 6.53704ZM1.64167 7.4173C2.34246 6.50247 3.52382 5.11719 5.00463 3.9667C6.4936 2.80988 8.21151 1.94752 10.0003 1.94752C11.789 1.94752 13.5069 2.80988 14.9959 3.9667C16.4764 5.11696 17.6576 6.50192 18.3584 7.41675C18.6176 7.75579 18.6172 8.20304 18.3588 8.54052C17.658 9.45535 16.4766 10.8406 14.9959 11.991C13.5069 13.1478 11.789 14.0102 10.0003 14.0102C8.21151 14.0102 6.4936 13.1478 5.00463 11.991C3.52382 10.8405 2.34246 9.45524 1.64167 8.54041L1.63868 8.53654C1.51478 8.37704 1.44752 8.18082 1.44752 7.97886C1.44752 7.77689 1.51478 7.58068 1.63868 7.42118L1.64167 7.4173Z'
        fill='var(--color-gray-50)'
        fillRule='evenodd'
      />
    </svg>
  );
}
