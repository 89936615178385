import { useQuery } from '@tanstack/react-query';

import { fetchData } from '..';

import type { QueryKey } from '@tanstack/react-query';

export type Segment = {
  AudienceSize: number;
  CreatedBy: string;
  CreatedOnUtc: string;
  Definition: string | null;
  IsDeleted: boolean;
  IsUpdating: boolean;
  LastEditedUtc: string | null;
  Name: string;
  SegmentId: number;
};

export const segmentsQueryKey: QueryKey = ['segments'];

export function useGetSegmentsQuery() {
  return useQuery({
    queryKey: segmentsQueryKey,
    queryFn: () => fetchData<Segment[]>('api/segment/get-segment-list'),
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: (segmentListData) => (segmentListData?.some((data) => data.IsUpdating) ? 3000 : false),
  });
}
