import React from 'react';

import { BlueProgressBadge, GreenFilledCheckBadge, RedErrorBadge } from 'src/app/components/lib/badge-v2';
import { Button, ButtonGroup } from 'src/app/components/lib/button';
import { Divider } from 'src/app/components/lib/divider';
import { FormSection } from 'src/app/components/lib/form';
import { FormControl } from 'src/app/components/lib/form-control';
import { Heading } from 'src/app/components/lib/heading';
import { Input } from 'src/app/components/lib/input';
import { Label } from 'src/app/components/lib/label';
import { useCheckMailjetDns } from 'src/app/queries/integrations/mailjet/check-dns';
import { useNotificationActions } from 'src/app/state/notifications';

import type { MailJetCheckDnsResult } from 'src/app/queries/integrations/mailjet/check-dns';
import type { MailjetDnsRecord } from 'src/app/queries/integrations/mailjet/get-mailjet-domains';

type MailJetDomainProps = {
  domain: MailjetDnsRecord;
  onComplete?: () => void;
};

export default function MailJetDns({ domain: dns, onComplete }: MailJetDomainProps) {
  const SPF_VALUE = 'include:spf.mailjet.com';
  const notifications = useNotificationActions();
  const { mutate: checkDns, isLoading: isChecking } = useCheckMailjetDns({
    onSuccess: async (x: MailJetCheckDnsResult) => {
      if (x.DKIMErrors?.length > 0) {
        notifications.error({ message: `DKIM errors: ${x.DKIMErrors.join(', ')}`, sticky: true });
      }
      if (x.SPFErrors?.length > 0) {
        notifications.error({ message: `SPF errors: ${x.SPFErrors.join(', ')}`, sticky: true });
      }

      if (x.DKIMErrors?.length <= 0 && x.SPFErrors?.length <= 0) {
        notifications.success({ message: `SPF Status: ${x.SPFStatus} \nDKIM Status: ${x.DKIMStatus}` });
      }
    },
    onError: () => {
      notifications.error({ message: 'Failed to validate domain' });
    },
  });

  const showDKIMStatusBadge = (status) => {
    switch (status) {
      case 'Not checked':
        return <BlueProgressBadge label='Not checked' />;
      case 'OK':
        return <GreenFilledCheckBadge label='OK' />;
      case 'Error':
        return <RedErrorBadge label='Error' />;
      default:
        return <RedErrorBadge label='Unknown' />;
    }
  };

  const showSPFStatusBadge = (status) => {
    switch (status) {
      case 'Not checked':
        return <BlueProgressBadge label='Not checked' />;
      case 'OK':
        return <GreenFilledCheckBadge label='OK' />;
      case 'Not found':
        return <RedErrorBadge label='Not found' />;
      case 'Error':
        return <RedErrorBadge label='Error' />;
      default:
        return <RedErrorBadge label='Unknown' />;
    }
  };

  return (
    <>
      <FormSection>
        <Heading>Editing domain: {dns.Domain}</Heading>
        {dns.DKIMRecordName && (
          <>
            <Heading>Set up DKIM</Heading>
            <Input
              disabled
              endAdornment={
                <Button
                  buttonSize='small'
                  label='Copy'
                  variant='secondary'
                  onClick={async () => {
                    await navigator.clipboard.writeText(dns.DKIMRecordName);
                    notifications.success({ message: 'DKIM name copied to clipboard' });
                  }}
                />
              }
              label='Your DKIM record should look like this:'
              value={dns.DKIMRecordName}
            />
            <Input
              disabled
              endAdornment={
                <Button
                  buttonSize='small'
                  label='Copy'
                  variant='secondary'
                  onClick={async () => {
                    await navigator.clipboard.writeText(dns.DKIMRecordValue);
                    notifications.success({ message: 'DKIM value copied to clipboard' });
                  }}
                />
              }
              label='in TXT (2048 bits):'
              tooltip='Please make sure it stays on the one line!'
              value={dns.DKIMRecordValue}
            />
            <FormControl>
              <Label>Status:</Label>
              <span>{showDKIMStatusBadge(dns.DKIMStatus)}</span>
            </FormControl>
          </>
        )}

        {dns.SPFRecordValue && (
          <>
            <Heading>Set up SPF</Heading>
            <Input
              disabled
              endAdornment={
                <Button
                  buttonSize='small'
                  label='Copy'
                  variant='secondary'
                  onClick={async () => {
                    await navigator.clipboard.writeText(SPF_VALUE);
                    notifications.success({ message: 'SPF value copied to clipboard' });
                  }}
                />
              }
              label='SPF value:'
              value={SPF_VALUE}
            />
            <Input
              disabled
              endAdornment={
                <Button
                  buttonSize='small'
                  label='Copy'
                  variant='secondary'
                  onClick={async () => {
                    await navigator.clipboard.writeText(dns.Domain);
                    notifications.success({ message: 'SPF domain copied to clipboard' });
                  }}
                />
              }
              label='Add this record in your DNS zone:'
              value={dns.Domain}
            />
            <Input
              disabled
              endAdornment={
                <Button
                  buttonSize='small'
                  label='Copy'
                  variant='secondary'
                  onClick={async () => {
                    await navigator.clipboard.writeText(dns.SPFRecordValue);
                    notifications.success({ message: 'SPF record copied to clipboard' });
                  }}
                />
              }
              label='in TXT:'
              value={dns.SPFRecordValue}
            />
            <FormControl>
              <Label>Status:</Label>
              <span>{showSPFStatusBadge(dns.SPFStatus)}</span>
            </FormControl>
          </>
        )}
      </FormSection>
      <Divider variant='end-of-form' />
      <ButtonGroup>
        <Button label='Validate' loading={isChecking} type='button' onClick={() => checkDns({ Domain: dns.Domain })} />
        <Button label='Cancel' loading={isChecking} type='button' variant='secondary' onClick={onComplete} />
      </ButtonGroup>
    </>
  );
}
