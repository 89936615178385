import { useMutation } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import type { AxiosError } from 'axios';

export type CheckDnsRequest = {
  Domain: string;
};

export type MailJetCheckDnsResult = {
  DKIMErrors: string[];
  DKIMRecordCurrentValue: string;
  DKIMStatus: string;
  SPFErrors: string[];
  SPFRecordCurrentValue: string;
  SPFStatus: string;
};

export function useCheckMailjetDns({ onSuccess, onError }) {
  return useMutation<CheckDnsRequest, AxiosError<{ Data: string; Message: string }>, CheckDnsRequest>({
    mutationFn: (domain: CheckDnsRequest) =>
      postData<CheckDnsRequest>({
        payload: domain,
        endpoint: 'api/mailjet/check-dns',
      }),
    onSuccess,
    onError,
  });
}
