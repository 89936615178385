import React from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import { TrashCan } from 'src/app/components/icons/trash-can';
import { Button } from 'src/app/components/lib/button';
import { MenuItem } from 'src/app/components/lib/menu-item';
import { Select } from 'src/app/components/lib/select';
import useQueryBuilder from 'src/app/hooks/use-query-builder';
import { segmentsQueryKey, useGetSegmentsQuery } from 'src/app/queries/segment/get-segments';
import { useSaveSegmentQueryMutation } from 'src/app/queries/segment/save-segment-query';
import { useNotificationActions } from 'src/app/state/notifications';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import { OperatorInput } from './operator-input';
import {
  RuleGroupContainer,
  StyledIconButton,
  Condition,
  Attribute,
  StyledSelect,
  VerticalLine,
  QueryBuilderContainer,
  CombinatorBadge,
  QueryActions,
  StyledContentCard,
} from './query-builder.styles';

import type { CustomerSegmentSchemaMap } from '../../__tests__/schema';
import type { ClientError } from 'graphql-request';
import type { RuleGroup } from 'src/app/hooks/use-query-builder';

type QueryBuilderProps = {
  customerSegmentSchema: CustomerSegmentSchemaMap;
  disabled?: boolean;
  handleDrawerClose?: () => void;
  segmentDetails?: RuleGroup;
  segmentId: number | null;
  segmentName?: string;
};

export default function QueryBuilder({
  disabled,
  customerSegmentSchema,
  segmentDetails,
  segmentName,
  segmentId,
  handleDrawerClose,
}: QueryBuilderProps) {
  const queryClient = useQueryClient();
  const { ruleGroup, addRule, removeRule, updateRule, updateCombinator } = useQueryBuilder({
    segmentDetails,
  });
  const { mutate: saveQueryBuilderForSegmentMutator, isLoading } = useSaveSegmentQueryMutation();
  const { data: segments = [] } = useGetSegmentsQuery();
  const notifications = useNotificationActions();
  const { rules, combinator } = ruleGroup;
  const canEditSegments = usePermissionCheck([Permissions.EditSegments]);

  const handleConditionChange = (id: string, value: string) => {
    updateRule(id, { Condition: value, Attribute: '', Operator: '', Value: '' });
  };

  const handleAttributeChange = (id: string, value: string) => {
    updateRule(id, { Attribute: value, Operator: '', Value: '' });
  };

  const handleOperatorChange = (id: string, operator: string) => {
    updateRule(id, { Operator: operator, Value: '', SecondValue: '', Variant: '' });
  };

  const handleValueChange = (id: string, value: string, secondValue?: boolean) => {
    if (secondValue) {
      updateRule(id, { SecondValue: value });
    } else {
      updateRule(id, { Value: value });
    }
  };

  const handleVariantChange = (id: string, variant: string) => {
    updateRule(id, { Variant: variant });
  };

  // save query
  const saveQuery = () => {
    const name = segmentName ?? '';

    if (name === '') {
      notifications.error({ message: 'Please enter a segment name' });
      return;
    }

    if (segments.find((segment) => segment.Name.toLowerCase() === name.toLowerCase())) {
      notifications.error({ message: 'A segment with this name already exists' });
      return;
    }

    saveQueryBuilderForSegmentMutator(
      {
        Name: name,
        RuleGroup: ruleGroup,
        SegmentId: segmentId,
      },
      {
        onSuccess: () => {
          notifications.success({ message: 'Segment query saved successfully' });
          handleDrawerClose?.();
          void queryClient.invalidateQueries({ queryKey: segmentsQueryKey });
        },
        onError: (e) => {
          const error = e as ClientError;
          const { data, status } = error.response;
          // a 400 error will return a user-friendly error message
          if (status === 400) {
            notifications.error({ message: data.Message });
          } else {
            notifications.error({ message: 'Error saving segment query' });
          }
        },
      }
    );
  };

  return (
    <>
      <StyledContentCard
        title={
          disabled
            ? 'Customers in this segment meet the following conditions:'
            : 'Add customers who meet the following conditions:'
        }
      >
        <QueryBuilderContainer>
          <RuleGroupContainer>
            {rules.map((rule, index) => (
              <React.Fragment key={rule.Id}>
                <Condition>
                  <Select
                    automationId={`query-builder_select-condition_select-${String(index)}`}
                    disabled={disabled}
                    placeholder='Select a condition'
                    value={rule.Condition}
                    onChange={({ target }) => handleConditionChange(String(rule.Id), target.value)}
                  >
                    {Object.values(customerSegmentSchema).map((segment) => (
                      <MenuItem
                        automationId={`select-condition_${segment.value.toLowerCase()}_option-${String(index)}`}
                        key={segment.id}
                        value={segment.value}
                      >
                        {segment.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Condition>

                {rule.Condition && (
                  <Attribute>
                    <StyledSelect
                      automationId={`query-builder_select-attribute_select-${String(index)}`}
                      disabled={disabled}
                      paddingRightInput='42px'
                      placeholder='Select an attribute'
                      value={rule.Attribute}
                      onChange={({ target }) => handleAttributeChange(String(rule.Id), target.value)}
                    >
                      {customerSegmentSchema[rule.Condition].attributes.map((attribute) => (
                        <MenuItem
                          automationId={`select-condition_${String(attribute.name.toLowerCase())}_option`}
                          key={attribute.name}
                          value={attribute.name}
                        >
                          {attribute.label}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                    {rule.Attribute && (
                      <OperatorInput
                        disabled={disabled}
                        index={index}
                        operator={rule.Operator}
                        operatorGroup={
                          customerSegmentSchema[rule.Condition].attributes.find(
                            (attribute) => attribute.name === rule.Attribute
                          )?.operatorGroup
                        }
                        secondValue={rule.SecondValue}
                        value={rule.Value ?? ''}
                        variant={rule.Variant}
                        variants={
                          customerSegmentSchema[rule.Condition].attributes.find(
                            (attribute) => attribute.name === rule.Attribute
                          )?.variants
                        }
                        onOperatorChange={(operator) => handleOperatorChange(String(rule.Id), operator)}
                        onValueChange={(value, secondValue) => handleValueChange(String(rule.Id), value, secondValue)}
                        onVariantChange={(variant) => handleVariantChange(String(rule.Id), variant)}
                      />
                    )}
                    <VerticalLine />
                    {!disabled && (
                      <StyledIconButton onClick={() => removeRule(String(rule.Id))}>
                        <TrashCan />
                      </StyledIconButton>
                    )}
                  </Attribute>
                )}
                {index < rules.length - 1 && <CombinatorBadge label={combinator?.toUpperCase()} />}
              </React.Fragment>
            ))}
          </RuleGroupContainer>
          {!disabled && (
            <QueryActions>
              <Select placeholder='AND/OR' value={combinator} onChange={({ target }) => updateCombinator(target.value)}>
                <MenuItem value='and'>AND</MenuItem>
                <MenuItem value='or'>OR</MenuItem>
              </Select>
              <Button label='Add rule' variant='secondary' onClick={addRule} />
            </QueryActions>
          )}
        </QueryBuilderContainer>
      </StyledContentCard>
      {!disabled && (
        <Button disabled={!canEditSegments || isLoading} label='Save' loading={isLoading} onClick={saveQuery} />
      )}
    </>
  );
}
