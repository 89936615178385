import React from 'react';

import styled from 'styled-components';

import Translate from 'src/app_deprecated/components/form/layout/Translate.react';

import { HeaderContainer } from 'src/app/components/lib/header-container';
import { Heading } from 'src/app/components/lib/heading';
import { applyUuidToDataGridRows, DataGrid } from 'src/app/components/lib/table';
import { useMailjetDomainsQuery } from 'src/app/queries/integrations/mailjet/get-mailjet-domains';

import MailJetDns from './mailjet-domain';

export default function MailJetDomainList() {
  const { data, isLoading, refetch } = useMailjetDomainsQuery();
  const [selected, setSelected] = React.useState<any>(undefined);

  if (selected) {
    return (
      <MailJetDns
        domain={selected}
        onComplete={async () => {
          setSelected(undefined);
          await refetch();
        }}
      />
    );
  }

  return (
    <>
      <HeaderContainer>
        <StyledHeading>
          <Translate>Domains</Translate>
        </StyledHeading>
      </HeaderContainer>
      <DataGrid
        autoHeight
        columns={[
          { field: 'Domain', headerName: 'Domain', width: 200 },
          { field: 'DKIMStatus', headerName: 'DKIM', width: 100 },
          { field: 'SPFStatus', headerName: 'SPF', width: 100 },
        ]}
        loading={isLoading}
        name='MailJetSenderList'
        rows={applyUuidToDataGridRows(data)}
        onRowClick={(row) => setSelected(row.row)}
      />
    </>
  );
}

const StyledHeading = styled(Heading)`
  margin-bottom: -1;
`;
