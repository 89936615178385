import { useMutation } from '@tanstack/react-query';

import { postData } from 'src/app/queries';

import type { AxiosError } from 'axios';

export type UpdateMailjetSettings = {
  EmailAddress: string;
  IsDefault: boolean;
  Name: string;
  SenderId?: number;
};

export function useUpdateMailjetSettingsMutation({ onSuccess, onError }) {
  return useMutation<UpdateMailjetSettings, AxiosError<{ Data: string; Message: string }>, UpdateMailjetSettings>({
    mutationFn: (settings) =>
      postData({
        payload: settings,
        endpoint: 'api/mailjet/update-sender',
      }),
    onSuccess,
    onError,
  });
}
