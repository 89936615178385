import React, { useCallback, useMemo } from 'react';

import { CaretDown } from 'src/app/components/icons/caret-down';
import { Button } from 'src/app/components/lib/button';
import { useDarkMode } from 'src/app/state/dark-mode';

import { StyledActionsMenu } from './actions.styles';

import type { ButtonProps as MUIButtonProps, MenuProps as MUIMenuProps } from '@mui/material';
import type { ButtonProps } from 'src/app/components/lib/button';

type ActionsProps = {
  buttonLabel?: string;
  ButtonProps?: MUIButtonProps;
  buttonSize?: ButtonProps['buttonSize'];
  buttonVariant?: ButtonProps['variant'];
  buttonWidth?: string;
  children: React.ReactNode;
  MenuProps?: MUIMenuProps;
};

export const ActionsContext = React.createContext({ handleClose: () => undefined });

export function Actions(props: ActionsProps) {
  const darkMode = useDarkMode();
  const {
    buttonLabel = 'Actions',
    buttonWidth,
    buttonSize = 'medium',
    buttonVariant = 'secondary',
    children,
    ButtonProps,
    MenuProps,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const contextValue = useMemo(() => ({ handleClose }), [handleClose]);

  return (
    <>
      <Button
        {...ButtonProps}
        aria-controls='actions-menu'
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        automationId='user-actions-menu-button'
        buttonSize={buttonSize}
        endIcon={<CaretDown fill={darkMode ? 'var(--color-brand-primary-white)' : undefined} />}
        id='actions-menu-button'
        label={buttonLabel}
        sx={{ width: buttonWidth }}
        variant={buttonVariant}
        onClick={handleClick}
      />
      <StyledActionsMenu
        {...MenuProps}
        $darkMode={darkMode}
        anchorEl={anchorEl}
        id='actions-menu'
        MenuListProps={{
          'aria-labelledby': 'actions-menu-button',
        }}
        open={open}
        onClose={handleClose}
      >
        <ActionsContext.Provider value={contextValue}>{children}</ActionsContext.Provider>
      </StyledActionsMenu>
    </>
  );
}
