import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Heading } from 'src/app/components/lib/heading';

type IntegrationHeaderProps = {
  title: string;
};

export default function IntegrationHeader({ title }: IntegrationHeaderProps) {
  const navigate = useNavigate();

  return <Heading onBackAction={() => navigate('/settings/integrations')}>{title}</Heading>;
}
