import React from 'react';

import { Controller } from 'react-hook-form';

import { Toggle } from 'src/app/components/lib/toggle';

type ToggleFieldProps = {
  automationId?: string;
  disabled?: boolean;
  disabledText?: string;
  enabledText?: string;
  gridColumns?: number;
  helperText?: string;
  hideToggleState?: boolean;
  label?: React.ReactElement | string;
  name: string;
  required?: boolean;
  toggleGridColumn?: string;
  tooltip?: string;
};

export function ToggleField(props: ToggleFieldProps) {
  const {
    automationId,
    disabledText,
    enabledText,
    gridColumns,
    helperText,
    hideToggleState,
    label,
    name,
    required,
    toggleGridColumn,
    tooltip,
    disabled,
  } = props;

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Toggle
          automationId={automationId}
          checked={field.value ?? false}
          disabled={disabled}
          disabledText={disabledText}
          enabledText={enabledText}
          gridColumns={gridColumns}
          helperText={helperText}
          hideToggleState={hideToggleState}
          label={label}
          required={required}
          toggleGridColumn={toggleGridColumn}
          tooltip={tooltip}
          onBlur={field.onBlur}
          onChange={(event) => field.onChange(event.target.checked)}
        />
      )}
    />
  );
}
