import { Switch as MUISwitch } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledToggle = styled(MUISwitch)<{ checked: boolean }>`
  ${({ checked }) => css`
    ${checked && CheckedToggleStyles}

    ${!checked && UncheckedToggleStyles}
  `}

  width: var(--sizes-90);
  height: 28px;
  margin-right: var(--sizes-30);
  border-radius: 14px; // half of height for pill shape
  overflow: visible;

  .MuiSwitch-track {
    display: none;
  }

  .MuiSwitch-thumb {
    position: absolute;
    top: 2px;
    z-index: 1;
    color: var(--color-brand-primary-white);
    border-radius: 100px;
    width: var(--sizes-60);
    height: var(--sizes-60);
  }

  + span {
    font: var(--font-extra-small-12pt-bold);
    text-transform: uppercase;
  }
`;

const CheckedToggleStyles = css`
  background: var(--color-brand-secondary-sapphire);

  .Mui-checked {
    padding: 0;
    left: 2px;
    top: 2px;
  }

  + span {
    color: var(--color-brand-secondary-sapphire);
  }
`;

const UncheckedToggleStyles = css`
  background: var(--color-gray-40);

  .MuiSwitch-thumb {
    left: 2px;
  }

  .MuiButtonBase-root {
    :hover {
      background: none;
    }
  }

  .MuiSwitch-input {
    left: 0;
    top: 5px;
    z-index: 3;
  }

  + span {
    color: var(--color-gray-60);
  }
`;

export const StyledToggleContainer = styled.div<{ $fullWidth?: boolean; $toggleGridColumn: string }>`
  grid-column: ${({ $fullWidth, $toggleGridColumn }) => ($fullWidth ? 'span 6' : $toggleGridColumn)};
`;

export const StyledHelpText = styled.div`
  margin-top: var(--sizes-30);
  color: var(--color-gray-80);
  font: var(--font-small-13pt-normal);
`;
