import React from 'react';

import i18next from 'i18next';

import { Permissions } from 'src/app_deprecated/constants/PermissionsConstants';

import TabPage from 'src/app/layout/main/tab-page';
import { usePermissionCheck } from 'src/app/state/user-permissions';

import IntegrationHeader from '../integration-header';

import MailJetDomainList from './sender/mailjet-domain-list';
import MailJetSenderList from './sender/mailjet-sender-list';

import type { TabLink } from 'src/app/layout/main/tab-page';

export default function MailJetIntegration() {
  const hasPermissionToAccessConfiguration = usePermissionCheck(
    [Permissions.AccessEmailMarketing, Permissions.SuperUser],
    'or'
  );

  const tabs: TabLink[] = [
    {
      label: 'Senders',
      to: 'senders',
      hasPermission: hasPermissionToAccessConfiguration,
      element: <MailJetSenderList />,
      hasNestedRoutes: false,
    },
    {
      label: 'Domains',
      to: 'domains',
      hasPermission: hasPermissionToAccessConfiguration,
      element: <MailJetDomainList />,
      hasNestedRoutes: false,
    },
  ];

  return <TabPage header={<IntegrationHeader title={i18next.t('Email Marketing')} />} tabs={tabs} />;
}
