// adding a comment here to not break the tests -- DO NOT DELETE please (:

export type Operator = {
  label: string;
  name: string;
  valueType?: Value;
};

// TODO: add validation on the operators and their associated elements they populate
// this will be determined by the valueType
export const dateOperators: Operator[] = [
  { name: '=', label: 'on', valueType: 'date' },
  { name: '>', label: 'after', valueType: 'date' },
  { name: '<', label: 'before', valueType: 'date' },
  { name: 'between', label: 'between', valueType: 'dateRange' },
  { name: '>=', label: 'in the last', valueType: 'number' },
  { name: '<=', label: 'in the next', valueType: 'number' },
];

// some date operators reference the past only
export const historicDateOperators: Operator[] = [
  { name: '=', label: 'on', valueType: 'date' },
  { name: '>', label: 'after', valueType: 'date' },
  { name: '<', label: 'before', valueType: 'date' },
  { name: 'between', label: 'between', valueType: 'dateRange' },
  { name: '>=', label: 'in the last', valueType: 'number' },
  { name: '<=', label: 'before number of days ago', valueType: 'number' },
];

export const numberOperators: Operator[] = [
  { name: '=', label: 'exactly', valueType: 'number' },
  { name: '>', label: 'more than', valueType: 'number' },
  { name: '<', label: 'less than', valueType: 'number' },
  { name: 'between', label: 'between', valueType: 'numberRange' },
];

export const percentileOperators: Operator[] = [
  { name: '>=', label: 'top', valueType: 'percent' },
  { name: '<=', label: 'bottom', valueType: 'percent' },
];

export const stringOperators: Operator[] = [
  { name: '=', label: 'is', valueType: 'string' },
  { name: '!=', label: 'is not', valueType: 'string' },
  { name: 'like', label: 'like', valueType: 'string' },
  { name: 'not like', label: 'not like', valueType: 'string' },
];

export const booleanOperators: Operator[] = [{ name: '=', label: 'is', valueType: 'boolean' }];

export const productCategoryOperators: Operator[] = [
  { name: 'in', label: 'is in', valueType: 'productCategory' },
  { name: 'not in', label: 'is not in', valueType: 'productCategory' },
];

export const createEqualsOperators = (valueType: Value): Operator[] => [
  { name: '=', label: 'is', valueType },
  { name: '!=', label: 'is not', valueType },
];

export const equalsOperators = createEqualsOperators('stateLocation');
export const equalsCustomerTypeOperators = createEqualsOperators('customerType');

const BaseEnum = {
  Boolean: 'boolean',
  Date: 'date',
  HistoricDate: 'historicDate',
  Number: 'number',
  Percent: 'percent',
  ProductCategory: 'productCategory',
  StateLocation: 'stateLocation',
  String: 'string',
  CustomerType: 'customerType',
} as const;

export const ValueEnum = {
  ...BaseEnum,
  DateRange: 'dateRange',
  NumberRange: 'numberRange',
} as const;

export type Value = (typeof ValueEnum)[keyof typeof ValueEnum];

export const OperatorGroupEnum = {
  ...BaseEnum,
} as const;

export type OperatorGroup = (typeof OperatorGroupEnum)[keyof typeof OperatorGroupEnum];

export function getOperatorsFromValueType(operatorGroup?: OperatorGroup) {
  switch (operatorGroup) {
    case OperatorGroupEnum.Date:
      return dateOperators;
    case OperatorGroupEnum.HistoricDate:
      return historicDateOperators;
    case OperatorGroupEnum.Number:
      return numberOperators;
    case OperatorGroupEnum.Percent:
      return percentileOperators;
    case OperatorGroupEnum.String:
      return stringOperators;
    case OperatorGroupEnum.Boolean:
      return booleanOperators;
    case OperatorGroupEnum.ProductCategory:
      return productCategoryOperators;
    case OperatorGroupEnum.StateLocation:
      return equalsOperators;
    case OperatorGroupEnum.CustomerType:
      return equalsCustomerTypeOperators;
    default:
      return stringOperators;
  }
}
