import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TextField } from 'src/app/components/fields/text-field';
import { ToggleField } from 'src/app/components/fields/toggle-field';
import { Button, ButtonGroup } from 'src/app/components/lib/button';
import { Divider } from 'src/app/components/lib/divider';
import { FormContainer, FormSection } from 'src/app/components/lib/form';
import { useUpdateMailjetSettingsMutation } from 'src/app/queries/integrations/mailjet/create-update-sender';
import { useNotificationActions } from 'src/app/state/notifications';

import type { FormDataType } from 'src/app/types/forms-helpers';

type Sender = {
  emailAddress: string;
  isDefault: boolean;
  name: string;
};

type MailJetSenderProps = {
  onComplete?: () => void;
  sender: Sender;
};

export const schema = yup.object().shape({
  name: yup.string().default('').max(1000),
  emailAddress: yup.string().default('').max(1000),
  isDefault: yup.bool().default(false),
});

export type MailJetFormSettings = FormDataType<typeof schema>;
const defaultValues: MailJetFormSettings = schema.cast({});

export default function MailJetSender({ sender, onComplete }: MailJetSenderProps) {
  const notifications = useNotificationActions();
  const { mutate: updateSettingsMutator, isLoading: isUpdating } = useUpdateMailjetSettingsMutation({
    onSuccess: onComplete,
    onError: () => {
      notifications.error({ message: 'Failed to update sender' });
    },
  });
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { reset } = formMethods;

  useEffect(() => {
    if (sender) {
      reset(
        schema.cast({
          ...sender,
        })
      );
    } else {
      reset(sender);
    }
  }, [reset, sender]);

  return (
    <FormProvider {...formMethods}>
      <FormContainer>
        <form
          onSubmit={formMethods.handleSubmit((data) =>
            updateSettingsMutator({
              EmailAddress: data.emailAddress,
              IsDefault: data.isDefault,
              Name: data.name,
              SenderId: undefined,
            })
          )}
        >
          <FormSection>
            <TextField label='Sender Name:' name='name' />
            <TextField label='Email:' name='emailAddress' tooltip='The email address to send marketing emails from' />
            <ToggleField label='Default:' name='isDefault' />
          </FormSection>
          <Divider variant='end-of-form' />
          <ButtonGroup>
            <Button label='Update' loading={isUpdating} type='submit' />
            <Button label='Cancel' loading={isUpdating} type='button' variant='secondary' onClick={onComplete} />
          </ButtonGroup>
        </form>
      </FormContainer>
    </FormProvider>
  );
}
